
[data-sparta-container] .spa-ui-info .spa-card {


  @include card-sizing(4px, !important, false);
}

[data-sparta-container] .spa-card {


  @include spa-card;



  @include card-sizing();

  &--overlay {
    @include shadow-default();
  }

  &__head {
    .spa-card__title {
      order: 1;
      max-width: calc(100% - 44px);
      margin: 0;
      flex-grow: 1;
      &,
      & h1,
      & h2,
      & h3,
      & h4,
      & h5,
      & h6 {
        font-family: $cnx-bold-family;
        color: $color-black;
      }
    }

    .spa-card__dismissible {
      order: 2;
      background-color: transparent;
      background-position: center center;
      padding: 0;
      margin: -$spacing-8 6px 0 auto;
      outline: none;
      cursor: pointer;
      background-image: icon(close, (color: $color-gray));
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: 44px;
      border-radius: $spacing-2;

        &:focus {
        border: 1px solid $link-color;
      }

      &-white {
        background-image: icon(close, (color: $color-brand-tertiary));

        &:hover, 
        &:focus {
         border: 1px solid $color-brand-tertiary;
         background-image: icon(close, (color: $color-brand-tertiary));
        }

        &::before,
        &::after {
          opacity: 0 !important;
        }
      }


      &.spa-ui-layer-close {
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }


  &__head-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    padding: 0;
    &-none {
      background-color: $color-brand-tertiary;
      border-top-left-radius: $spacing-12;
      border-top-right-radius: $spacing-12;
    }
    &-red {
      background-color: $color-regal-one;
      border-top-left-radius: $spacing-12;
      border-top-right-radius: $spacing-12;
      .spa-card__title {
        color: $color-brand-tertiary;
      }
    }
    &-blue {
      background-color: $color-brand-secondary;
      border-top-left-radius: $spacing-12;
      border-top-right-radius: $spacing-12;
      .spa-card__title {
        color: $color-brand-tertiary;
      }
    }
    &-gray {
      background-color: $color-gray-s20;
      border-top-left-radius: $spacing-12;
      border-top-right-radius: $spacing-12;
    }
  }

  .show-on-focus {
    &:focus {
      position: relative;
      font-size: 12px;
      line-height: 14px;
      white-space: normal;
      height: auto;
      width: auto;
      opacity: 1;
      clip-path: none;
      outline: 2px dotted;
    }
  }


  &__foot {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &-link {
      flex-basis: 0;
      text-align: center;
      flex-grow: 1;
      padding: 12px 0px;
      border-top: 1px solid $color-gray-s20;
      border-right: 1px solid $color-gray-s20;
      &:last-child {
        border-right: none;
      }


      &.spa-btn--link {
        line-height: 24px;
        &:hover {
          color: $color-royal-one;
        }
      }
    }

    @mixin stackCardFootItems() {
      flex-flow: column;
      align-items: normal;
      .spa-card__foot-link {
        border-right: none;
      }
    }
    &-stack {
      @include stackCardFootItems();
    }

    @media #{$large-up} {
      &[data-stacked-desktop="true"] {
        @include stackCardFootItems();
      }
    }

    @media #{$medium-only} {
      &[data-stacked-tablet="true"] {
        @include stackCardFootItems();
      }
    }

    @media #{$small-only} {
      @include stackCardFootItems();
      &[data-stacked-mobile="false"] {
        flex-flow: row;
        align-items: center;
        .spa-card__foot-link {
          border-right: 1px solid $color-gray-s20;
        }
      }
    }

  }


  &__body-app-wrapper {
    margin: 0 0 12px 0;
  }

  &.spa-card__dismissible--none {
    .spa-card__title {
      max-width: 100%; 
    }
  }


  &[class*=" card-caret-"]:before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-bottom: 8px solid $color-brand-tertiary;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
  &.card-caret-top-right:before {
    left: auto;
    right: 12px;
  }
  &.card-caret-top-left:before {
    right: auto;
    left: 12px;
  }
  &[class*=" card-caret-bottom"]:before {
    transform: rotate(180deg);
    top: auto;
    bottom: -8px;
  }
  &.card-caret-bottom-right:before {
    left: auto;
    right: 12px;
  }
  &.card-caret-bottom-left:before {
    right: auto;
    left: 12px;
  }
  &[class*=" card-caret-left"]:before {
    transform: rotate(-90deg);
    left: -12px;
    right: auto;
  }
  &.card-caret-left:before {
    top: 0;
    bottom: 0;
  }
  &.card-caret-left-top:before {
    top: 12px;
    bottom: auto;
  }
  &.card-caret-left-bottom:before {
    top: auto;
    bottom: 12px;
  }
  &[class*=" card-caret-right"]:before {
    transform: rotate(90deg);
    left: auto;
    right: -12px;
  }
  &.card-caret-right:before {
    top: 0;
    bottom: 0;
  }
  &.card-caret-right-top:before {
    top: 12px;
    bottom: auto;
  }
  &.card-caret-right-bottom:before {
    top: auto;
    bottom: 12px;
  }
}
